<template>
  <div class="container flex flex-col flex-1">
    <b-overlay
      :show="loading"
      :opacity="0.85"
      variant="white"
      spinner-variant="primary"
      rounded="sm"
      blur="none"
      class="flex flex-col flex-1"
      overlay-tag="div"
    >
      <q-r-code
        v-if="userData"
        :isShowQr.sync="isShowQr"
        :codeVictim.sync="userData && userData.codeQR || ''"
      ></q-r-code>

      <h1 class="mt-3 mt-md-5 mb-3 text-center text-pri-900">Thông tin hành chính</h1>

      <div class="mb-4">
        <b-card class="product-add-wrapper mb-2">
          <div class="flex items-center mb-2">
            <h4 class="mb-0">Người Bệnh</h4>
            <div class="ml-auto cursor-pointer" @click="goToEditPage()">
              <span class="align-middle">Chỉnh sửa</span>
              <img class="ml-2 align-middle" src="../../../../public/icons/u_edit.svg" alt />
            </div>
          </div>

          <!-- User Info: Input Fields -->
          <b-form>
            <b-row>
              <!-- Field: Full Name -->
              <b-col cols="12" md="6" class="mb-2">
                <b-form-group label="Họ và tên" label-for="fullname">
                  <b-form-input readonly id="fullname" v-model="userData.fullName" />
                </b-form-group>
              </b-col>

              <!-- Field: Phone -->
              <b-col cols="12" md="6" class="mb-2">
                <b-form-group label="Số điện thoại" label-for="phone">
                  <b-form-input readonly id="phone" type="number" v-model="userData.phone" />
                </b-form-group>
              </b-col>

              <!-- Field: Date of birth -->
              <b-col cols="12" md="6" class="mb-2">
                <b-form-group label="Ngày sinh" label-for="email">
                  <b-form-input
                    readonly
                    pattern="\d{4}-\d{2}-\d{2}"
                    type="date"
                    v-model="formatterDOB"
                  />
                </b-form-group>
              </b-col>

              <!-- Field: Gender -->
              <b-col class="mt-1 mb-2" cols="12" md="6">
                <b-form-group label="Giới tính" class="mt-1">
                  <b-row>
                    <b-col>
                      <b-form-radio disabled value="1" v-model="userData.gender">
                        <span class="ml-2">Nam</span>
                      </b-form-radio>
                    </b-col>
                    <b-col>
                      <b-form-radio disabled value="2" v-model="userData.gender">
                        <span class="ml-2">Nữ</span>
                      </b-form-radio>
                    </b-col>
                  </b-row>
                </b-form-group>
              </b-col>
              <!-- Field: CCCD -->
              <b-col cols="12" md="12" class="mb-2">
                <b-form-group label="Căn cước công dân (CCCD/ID)" label-for="idCard">
                  <b-form-input readonly id="idCard" v-model="userData.idCard" />
                </b-form-group>
              </b-col>
              <!-- Field: BHYT -->
              <b-col cols="12" md="6" class="mb-2">
                <b-form-group label-for="bhytCode">
                  <label for="text-password">Mã số Bảo hiểm Y tế (VSSID) (nếu có)</label>
                  <b-form-input readonly id="bhytCode" v-model="userData.insurance_number" />
                </b-form-group>
              </b-col>
              <b-col cols="12" md="6" class="mb-2">
                <b-form-group>
                  <label for="text-password">Ngày hết hạn BHYT</label>
                  <b-form-input
                    readonly
                    pattern="\d{4}-\d{2}-\d{2}"
                    type="date"
                    v-model="userData.insurance_expire"
                  />
                </b-form-group>
              </b-col>
              <!-- Field: Emergency Contact -->
              <b-col cols="12" md="6" class="mb-2">
                <b-form-group label-for="emergencyContact">
                  <label for="text-password">Họ tên, địa chỉ người nhà khi cần báo tin</label>
                  <b-form-input
                    readonly
                    id="emergencyContact"
                    v-model="userData.emergency_contact"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12" md="6" class="mb-2">
                <b-form-group>
                  <label for="text-password">Số điện thoại liên hệ</label>
                  <b-form-input
                    readonly
                    id="contact_phone"
                    type="number"
                    v-model="userData.contact_phone"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </b-form>
        </b-card>
        <b-card class="product-add-wrapper mb-2">
          <h4 class="mb-2">Thông tin liên hệ</h4>
          <!-- User Info: Input Fields -->
          <b-form>
            <b-row>
              <!-- Field: Address -->
              <b-col cols="12" md="12" class="mb-2">
                <b-form-group label="Địa chỉ liên hệ" label-for="address">
                  <b-form-input readonly id="address" v-model="userData.address" />
                </b-form-group>
              </b-col>
              <!-- Field: City -->
              <b-col cols="12" md="6" class="mb-2">
                <b-form-group label="Tỉnh/thành phố" label-for="address-city">
                  <b-form-input readonly id="address-city" v-model="userData.city" />
                </b-form-group>
              </b-col>
              <!-- Field: distrirct -->
              <b-col cols="12" md="6" class="mb-2">
                <b-form-group label="Quận/huyện" label-for="address-district">
                  <b-form-input readonly id="address-district" v-model="userData.distrirct" />
                </b-form-group>
              </b-col>
              <!-- Field: Ward -->
              <b-col cols="12" md="6" class="mb-2">
                <b-form-group label="Xã/phường" label-for="address-ward">
                  <b-form-input readonly id="address-ward" v-model="userData.ward" />
                </b-form-group>
              </b-col>
              <!-- Field: Country -->
              <b-col cols="12" md="6" class="mb-2">
                <b-form-group
                  label="Quốc tịch"
                  label-for="address-country"
                  v-if="userData && userData.country"
                >
                  <b-form-input readonly id="address-country" v-model="userData.country.name" />
                </b-form-group>
              </b-col>
            </b-row>
          </b-form>
        </b-card>
        <b-row class="mb-2">
          <b-col cols="12" md="6" class="mb-2">
            <b-button
              @click="isShowQr=true"
              style="width: 100%; background-color: #20419B;"
            >Lấy mã để đăng ký khám</b-button>
          </b-col>
          <b-col cols="12" md="6" class="mb-2">
            <b-button
              @click="goToNextPage"
              style="width: 100%; background-color: #20419B;"
            >Đăng ký khám</b-button>
          </b-col>
        </b-row>
      </div>
    </b-overlay>
  </div>
</template>

<script>
import {
  BFormGroup,
  BRow,
  BFormInput,
  BForm,
  BCol,
  BCard,
  BFormRadio,
  BButton,
  BOverlay
} from 'bootstrap-vue'
import QRCode from './modal/QRCode.vue'
import {
  required,
  isPhoneNumber
} from '../../../../src/utils/validations/validations'
import 'vue-select/dist/vue-select.css'
import { mapGetters } from 'vuex'
import appUtils from '../../../utils/appUtils'

export default {
  name: 'MedicalRegisterReviewForm',
  components: {
    QRCode,
    BFormRadio,
    BCard,
    BFormGroup,
    BRow,
    BFormInput,
    BForm,
    BCol,
    BButton,
    BOverlay
  },
  data () {
    return {
      persons: [],
      locationData: [],
      cityOptions: [],
      provinOptions: [],
      wardsOptions: [],
      cityDetect: {},
      districtDetect: {},
      dial_code: 84,
      isPhoneNumber,
      required,
      fullName: null,
      phone: null,
      time_out: 0,
      step: 1,
      loading: false,
      otpCount: 0,
      user_id: null,
      deg: -90,
      isShowContractInfo: false,
      isShowQr: false,
      wsid: '',
      userData: {}
    }
  },
  beforeMount () {
    // this.getInfo()
    this.getPersons()
  },
  computed: {
    ...mapGetters('registerMedical', ['defaultWs', 'selectedWs']),
    formatterDOB: {
      get () {
        return appUtils.formatDate(this.userData.dateOfBirth)
      },
      set (newValue) {
        this.userData.dateOfBirth = newValue
        return newValue
      }
    }
  },
  methods: {
    goToEditPage () {
      this.$router.replace({
        path: '/form-information',
        query: {
          pId: this.$route.query?.pId
        }
      })
    },
    goToNextPage () {
      this.$store.commit('registerMedical/setUserStateData', this.userData)
      this.$router.replace({
        path: '/schedule-medical-service'
      })
    },
    async getPersons () {
      const params = {
        limit: 100
      }

      this.loading = true

      await this.$rf
        .getRequest('AuthRequest')
        .getPersons(params)
        .then(resp => {
          let user = null
          if (resp.data?.data?.length > 1) {
            user = resp.data?.data?.find(
              x => x.id === parseInt(this.$route.query?.pId)
            )
          } else {
            user = resp.data?.data[0] || this.$user?.person
          }
          this.userData = {
            ...user,
            id: user.id,
            fullName: user.name,
            phone: user.phone,
            dateOfBirth: user.birthday,
            gender: user.gender,
            idCard: user.cmnd,
            address: user.address,
            city: user.address_province_name,
            distrirct: user.address_district_name,
            codeQR: user.code,
            ward: user.address_wards_name,
            insurance_number: user.insurance_number,
            insurance_expire: user.insurance_expire,
            country: {
              code: user.national_id || '',
              name: user.nationality_name || ''
            }
          }
          this.loading = false
        })
        .catch(e => {
          this.loading = false
          window.$toast.open({
            message: 'Lỗi lấy thông tin danh sách người thân',
            type: 'warning'
          })
        })
        .finally(e => {
          this.loading = false
        })
    },
    async getInfo () {
      this.loading = true
      const self = this
      await self.$rf
        .getRequest('AuthRequest')
        .me()
        .then(rs => {
          console.log(rs.data)
          const user = rs.data
          this.userData = {
            id: user.person.id,
            fullName: user.name,
            phone: user.person.phone,
            dateOfBirth: user.person.birthday,
            gender: user.person.gender,
            idCard: user.person.cmnd,
            address: user.person.address,
            city: user.person.address_province_name,
            distrirct: user.person.address_district_name,
            codeQR: user.person.code,
            ward: user.person.address_wards_name,
            insurance_number: user.person.insurance_number,
            insurance_expire: user.person.insurance_expire,
            country: {
              code: user.person.national_id || '',
              name: user.person.nationality_name || ''
            }
          }
          this.loading = false
        })
    }
  }
}
</script>
<style scoped>
.form {
  justify-content: center;
  display: flex;
  align-items: center;
  min-height: 100vh;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.transition {
  width: 18px;
  transition: transform 0.1s ease-in-out;
}
</style>
