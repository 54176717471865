<template>
  <div>
    <b-modal
      id="modalQrCode"
      class="modal-backdrop"
      :visible="isShowQr"
      title="QR code"
      cancel-title="Close"
      centered
      ok-title="Accept"
      cancel-variant="outline-secondary"
      hide-footer
      @hide="$emit('update:is-show-qr', false)"
      @change="(val) => $emit('update:is-show-qr', val)"
    >
      <template #modal-header="{ close }">
        <h5 class="mb-0">QR code</h5>
        <!-- Emulate built in modal header close button action -->
        <b-button size="sm" variant="outline-secondary" @click="close()">&times;</b-button>
      </template>

      <b-col class="text-center" ref="printMe">
        <h3 style="color: #20409B;">Mã bệnh nhân</h3>
        <p class="fs-16" style="color: #20409B;">Dùng mã này Đặt khám tại Lễ tân</p>
        <qrcode-vue v-if="code" :value="code" :size="size"></qrcode-vue>
        <b-alert show variant="danger" class="text-center" v-else>
          <div>Hệ thống không tìm thấy mã QR Code.</div>
          <div>Vui lòng thử tải lại trang web.</div>
        </b-alert>
        <p class="fs-30" style="color: #20409B;">{{ code }}</p>
        <b-row>
          <b-col>
            <div
              @click="downloadVisualReport"
              :class="code ? 'cursor-pointer': 'disabled cursor-not-allowed'"
              role="button"
            >
              <img
                style="width: 20px"
                src="../../../../assets/images/medicalRegister/downloadImage.png"
                alt
              />
              <p class="fs-15 mb-0" style="color: #20409B;">Lưu ảnh</p>
            </div>
          </b-col>
          <b-col>
            <div :class="code ? 'cursor-pointer': 'disabled cursor-not-allowed'" role="button">
              <img
                style="width: 20px"
                src="../../../../assets/images/medicalRegister/printImage.png"
                alt
              />
              <p class="fs-15 mb-0" style="color: #20409B;">In mã</p>
            </div>
          </b-col>
        </b-row>
      </b-col>
    </b-modal>
  </div>
</template>
<script>
import {
  BModal,
  VBModal,
  BRow,
  BCol
  // BPagination,
} from 'bootstrap-vue'
import QrcodeVue from 'qrcode.vue'
import Ripple from 'vue-ripple-directive'
import html2canvas from 'html2canvas'
import { saveAs } from 'file-saver'

// import moment from 'moment'
export default {
  components: {
    QrcodeVue,
    BModal,
    BCol,
    BRow
  },
  directives: {
    'b-modal': VBModal,
    Ripple
  },
  props: {
    isShowQr: {
      type: Boolean,
      required: true
    },
    codeVictim: {
      type: String || null,
      required: true
    }
  },
  data () {
    return {
      value: 'abc',
      size: 200,
      output: null,
      code: this.codeVictim || ''
    }
  },
  watch: {
    codeVictim: {
      handler (data) {
        console.log(data)
        this.code = data || ''
      },
      deep: true
    }
  },
  methods: {
    showCaptureRef () {
      console.log(this.refs.printMe)
    },
    downloadVisualReport () {
      html2canvas(this.$refs.printMe)
        .then(canvas => {
          canvas.toBlob(blob => {
            saveAs(blob, `${this.codeVictim}.png`)
          })
        })
        .catch(_error => {
          console.log(_error)
        })
    }
  }
}
</script>
<style lang="scss">
.rotated {
  transform: rotate(-90deg);
  /* Equal to rotateZ(45deg) */
}
.modal-backdrop {
  opacity: 0.5 !important;
}
</style>
